var site = site || {};
site.userInfoCookie = site.userInfoCookie || {};
site.onLoadRpc = site.onLoadRpc || {};
site.onLoadRpc.requests = site.onLoadRpc.requests || [];
site.legacy_user_site = true;


site.onLoadRpc.init = function() {
  var requests = site.onLoadRpc.requests || [];
  var rLen     = requests.length;

  var signedIn = (site.userInfoCookie.getValue('signed_in') == 1 || site.userInfoCookie.getValue('csr_logged_in') == 1);
  if (signedIn) {
    site.onLoadRpc.requests.push({
      "method":   "user.fullData",
      "params":   [{}],
      "onSuccess" : function(data) {
        // This is for the Phone Order app to recognize values on Drupal pages
        if ( data === undefined || (data.result === undefined) || (data.result.value == null)) {
          return null;
        }

        var val = data.result.value;

        if ((val['csr_email'] !== undefined) && (val['csr_email'] !== null)){
          $('#csr_header_holder').removeClass('hidden');
        }

        // Set a body class attribute based on user logged in status
        $('body').addClass( (val.signed_in === 1) ? 'elc-user-state-logged-in' : 'elc-user-state-anonymous' );

        // Populate user data
        $('[data-pg-object="user"] > [data-pg-prop], [data-pg-object="user"][data-pg-prop]').each(function() {
          var $me = $(this);
          $me.html(val[$me.attr('data-pg-prop').toLowerCase()]);
        });

        // generic.user.setUser(val);

        $(document).trigger('user.loaded', val);
      },
      "onFailure" : function() {}
    });
  }
  else {
    // generic.user.setUser({});
    $('body').addClass('elc-user-state-anonymous');
    $(document).trigger('user.loaded', {});
  }
};

site.onLoadRpc.initUserCartData = function() {
  if (site.legacy_user_site) {
     site.onLoadRpc.requests.push({
       "method":  "analytics.usercart",
       "params":  [{}],
       "onSuccess" : function(data) {
         if ((data.result === undefined) || (data.result.value == null) || (data.result.value.userdata.sorted == null)) {
           window.utag_data = window.utag_data || {};
           utag_data.rpc_complete = true;
           return null;
         }
         // Set the cookie right away. Looked at combining it, but too many possible bugs this late before launch.
         // Also, Adobe libs load later than this JS does most of the time, so the s object is often undef at this point.
         $.cookie('Auser', decodeURI(data.result.value.userdata.sorted + '-' + data.result.value.userdata.ident), { expires: 360, path: '/' });
  
         // still store in tms_page_data, will use the cookie only as a backup.
         if (typeof tms_page_data != 'undefined') {
           if (typeof tms_page_data.tms_page_info != 'undefined') {
             tms_page_data.tms_page_info.user_info = data.result.value.userdata;
           }
         }
         // utag data for Tealium
         if (typeof utag_data == 'object') {
           utag_data.ELC_USER_ID = data.result.value.userdata.ident || '';
           utag_data.is_loyalty_member = data.result.value.userdata.is_loyalty_member || '';
           utag_data.is_pro = data.result.value.userdata.is_pro || '';
           utag_data.loyalty_level = data.result.value.userdata.loyalty_level || '';
           utag_data.mobile_email_optin = data.result.value.userdata.mobile_email_optin || '';
           utag_data.pc_email_optin = data.result.value.userdata.pc_email_optin || '';
           utag_data.recognized_user = data.result.value.userdata.recongnized_user || '';
           utag_data.registered_user = data.result.value.userdata.registered_user || '';
           // cart data
           utag_data.cart_product_id = data.result.value.cartdata.tms_product_lists.productIdList || '';
           utag_data.cart_product_price = data.result.value.cartdata.tms_product_lists.productPriceList || '';
           utag_data.cart_product_quantity = data.result.value.cartdata.tms_product_lists.productPriceQty || '';
           utag_data.cart_product_sku = data.result.value.cartdata.tms_product_lists.SkuIdList || '';
           utag_data.rpc_complete = true;
         }
       },
       "onFailure" : function() {
         window.utag_data = window.utag_data || {};
         utag_data.rpc_complete = true;
       }
     });
  }
};

/*
 * site.onLoadRpc.requests - a global array of RPC request objects
 * must be initialized pre-DOM-load and formatted like this:
 * [
 *     {
 *         "method":   "user.json",
 *         "params":   [{}],
 *         "getParams" : function () { return [{}] },  * one of 'params' or 'getParams' is required
 *         "onSuccess" : function () { },
 *         "onFailure" : function () { }
 *     }
 * ]
 */
site.onLoadRpc.fetch = function() {
  var requests = site.onLoadRpc.requests || [],
      rLen     = requests.length;
  var queryVals = [];

  for (var i = 0, len = rLen; i < len; i++) {
    // don't run analytics rpc on checkout path pages.
    if (requests[i]['method'] == 'analytics.cartinfo' && document.location.pathname.match(/checkout/) !== null){
      continue;
    }
    var postMethod = requests[i]['method'] || 'rpc.form';

    if (typeof requests[i]['getParams'] == 'function') {
      requests[i]['params'] = requests[i]['getParams']();
    }

    queryVals[i] = {
      "method": postMethod,
      "params": requests[i].params,
      "id": i + 1
    };
  }

  if (queryVals.length == 0) {
    return null;
  }

  var successHandler = function(data, textStatus, response) {
    for (var i = 0, len = rLen; i < len; i++) {
      var fn = requests[i].onSuccess;
      if (typeof fn !== 'function') {
        continue;
      }
      fn( data[i] );
    }
  };

  var url = '/rpc/jsonrpc.tmpl';
  var options = {};

  // ELCTWO-571 requires that we pass brand, region, and locale ids to ensure proper responses
  // on the pg side for drupal sites.  To accomplish this we pass 'addl_url_params' within the arguments.
  // This snippets searches for such entries and adds 'em to the request url.
  var url_params = '';

  // Default option
  if (Drupal.settings.use_jsonrpc) {
    $(queryVals).each(function() {
      if (this.params[0].url_params) {
        if (this.params[0].url_params.charAt(0) === '&') {
          url_params += this.params[0].url_params;
        } else {
          url_params += '&' + this.params[0].url_params;
        }
      }
    });
    if (url_params !== '') {
      url += '?' + url_params.substring(1);
    }

    options.data = $.param({JSONRPC: JSON.stringify(queryVals)});

    options.type = 'POST';
    options.success = function(data, textStatus, response) {
      if (response.getResponseHeader("Date")) {
        data[0]['result']['data']['responseDate'] = response.getResponseHeader("Date");
      }
      successHandler(data, textStatus, response);
    };
    options.error = function(jqXHR, textStatus, errorThrown) { };
    generic.jsonrpc.fetch({url: url, options: options});
  }

  // Microservice based sites
  else {
    var r;
    for (var k = 0, l = requests.length; k < l; k++) {
      r = requests[k];

      // Sample batch object
      // {
      //   method        : "<method>",
      //   apiFilterName : "<key>",
      //   params        : [{}],
      //   onSuccess     : function(data) { },
      //   onFailure     : function() {}
      // }

      if (!!r['getParams'] && typeof r['getParams'] === 'function') {
        r['params'] = r['getParams']();
      }

      generic.restapi.fetch({
        method        : r['method'] || '',
        path          : r['path'] || '',
        apiFilterName : r['apiFilterName'] || '',
        params        : r['params'] || [{}],
        onSuccess     : r['onSuccess'] || function() {},
        onFailure     : r['onFailure'] || function() {}
      });
    }
  }
};

$(function() {
  // Check if this is a tealium enabled site
  if ( Drupal.settings && Drupal.settings.analytics && Drupal.settings.analytics.tealium_profile ) {
    site.legacy_user_site = false;
  }
    
  // Set user cookie
  site.userInfoCookie.init();
  site.onLoadRpc.init();
  site.onLoadRpc.initUserCartData();
  site.onLoadRpc.fetch();

  if (window.location.hash === '#sign-in') {
    setTimeout(function () {
      $('#return-user-link').trigger('click');
    }, 500);
  }
  if (window.location.hash === '#new-account') {
    setTimeout(function () {
      $('#new-account-link').trigger('click');
    }, 500);
  }
});
